export const statusMap = {
    0 :{
        name:'待核价',
        tag:'info'
    },
    1 :{
        name:'核价中',
        tag:'info'
    }, 
    2 :{
        name:'核价成功',
        tag:'info'
    }, 
    3:{
        name:'核价失败',
        tag:'info'
    }, 
    4 :{
        name:'生成课包价格',
        tag:'info'
    }, 
    5 :{
        name:'待支付',
        tag:'info'
    },
    6 :{
        name:'用户购买',
        tag:'info'
    }, 
    7 :{
        name:'门店对接',
        tag:'info'
    }, 
    8 :{
        name:'门店接单',
        tag:'info'
    }, 
    9 :{
        name:'门店不接',
        tag:'info'
    },
    10 :{
        name:'已取消',
        tag:'info'
    }
}

export const typeMap = {
    1 :{
        name:'砍价',
        color:'#53a3ee'
    },
    2 :{
        name:'拆单节',
        color:'#f3a040'
    }
}

export const teachTypeMap = {
    1 :{
        name:'到店',
        tag:'info'
    },
    2 :{
        name:'上门',
        tag:'info'
    }, 
}